<script setup>
import CardIconComponent from "@/components/CardIconComponent";
import DateFilterComponent from "@/components/DateFilterComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import TitleComponent from "@/components/TitleComponent";
</script>

<template>
  <v-container class="py-5" style="max-width: 90vw">
    <v-snackbar v-model="snackbar" :timeout="2500" top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="justify-space-between align-center" style="display: flex">
      <TitleComponent title="Layanan Aset" />
      <v-btn
        @click="dialog = true"
        class="white--text text-capitalize"
        color="#004643"
        :disabled="!this.account.scopes.includes(this.scopeConstant.manage_asset)"
      >
        Unggah Aset
        <img
          :src="require('../assets/icons/ion_chevron-up-outline.svg')"
        />
      </v-btn>
    </div>
    <BreadcrumbsComponent :crumbs="crumbs" />

    <v-dialog v-model="dialog" width="auto">
      <v-card class="px-10 py-10">
        <v-layout row wrap justify-space-between align-center>
          <h1 style="font-size: 1.5rem; font-weight: 700">Unggah Aset</h1>
          <img
            style="cursor: pointer"
            @click="dialog = false"
            :src="require('../assets/icons/ph_x-bold.svg')"
          />
        </v-layout>
        <div
          class="dropzone-container mt-10"
          @dragover.prevent="dragover"
          @dragleave.prevent="dragleave"
          @drop.prevent="drop"
          ref="dropzone"
        >
          <input
            type="file"
            name="file"
            id="fileInput"
            class="hidden-input"
            @change="onChange"
            ref="fileInput"
            accept=".xlsx"
          />

          <label
            for="fileInput"
            class="file-label"
            style="color: #abb5be; font-size: 16px; width: 50vw"
          >
            <div v-if="isDragging">Drag file disini</div>
            <div
              v-else-if="!selectedFile"
              style="display: flex; flex-direction: column"
              class="justify-center align-center"
            >
              <img
                :src="require('../assets/icons/material-symbols_folder.svg')"
                style="width: 32px; height: auto"
                class="mb-1"
              />
              <div style="color: #abb5be; font-size: 16px">
                <u style="color: #004643; font-size: 16px">Cari</u> atau drag
                file untuk unggah aset Anda disini
              </div>
            </div>
            <div class="preview-container flex-column" v-if="selectedFile">
              <div
                class="preview-card flex-row justify-center align-center mx-auto"
              >
                <span>{{ selectedFile.name }}</span>
                <v-btn icon @click.prevent="clearSelectedFile">
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </div>
              <v-btn
                color="green"
                class="ma-2 white--text mx-auto"
                @click="uploadFile"
              >
                Upload
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
            </div>
          </label>
        </div>
        <div
          style="display: flex; flex-direction: column"
          class="justify-center align-center"
        >
          <div style="font-size: 14px; color: #001e1d" class="my-2">atau</div>
          <v-btn
            block
            style="
              border-radius: 5px;
              border: 1px #004643 solid;
              color: #004643;
              background: none;
              box-shadow: none;
            "
            href="/aset/create"
            >Tambahkan Aset Manual</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <DateFilterComponent
      :date-from="date_start"
      :date-to="date_end"
      :func="recallData"
    />
    <v-carousel>
      <v-carousel-item>
        <div
          class="my-5 rounded-xl"
          style="box-shadow: 0px 2px 10px 0px #e8e4e67d"
        >
          <v-layout row wrap justify-space-between align-center class="pa-5">
            <h2
              class="font-weight-bold"
              style="font-size: 1rem; font-weight: 700"
            >
              Fluktuasi Jumlah Aset
            </h2>
            <v-col cols="2" class="pa-0">
              <v-select
                :items="[
                  { label: 'Harian', type: 'day' },
                  { label: 'Mingguan', type: 'week' },
                  { label: 'Bulanan', type: 'month' },
                ]"
                v-model="type"
                item-text="label"
                item-value="type"
                dense
                solo
                height="10"
                hide-details
                @input="recallData"
              ></v-select>
            </v-col>
          </v-layout>

          <div id="chart1">
            <apexchart
              type="line"
              height="350"
              :options="chartOptionsAssetFluctuation"
              :series="assetFluctuation"
              ref="assetFluctuationChart"
            ></apexchart>
          </div>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div
          class="my-5 rounded-xl"
          style="box-shadow: 0px 2px 10px 0px #e8e4e67d"
        >
          <v-layout row wrap justify-space-between align-center class="pa-5">
            <h2
              class="font-weight-bold"
              style="font-size: 1rem; font-weight: 700"
            >
              Jumlah Aset Rusak
            </h2>
            <v-col cols="2" class="pa-0">
              <v-select
                :items="[
                  { label: 'Harian', type: 'day' },
                  { label: 'Mingguan', type: 'week' },
                  { label: 'Bulanan', type: 'month' },
                ]"
                v-model="type"
                item-text="label"
                item-value="type"
                dense
                solo
                height="10"
                hide-details
                @input="recallData"
              ></v-select>
            </v-col>
          </v-layout>

          <div id="chart2">
            <apexchart
              type="line"
              height="350"
              :options="chartOptionsDamagedAsset"
              :series="damagedAssets"
              ref="damagedAssetChart"
            ></apexchart>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>

    <div
      class="mt-8 px-5 py-5 justify-center align-center"
      style="
        background: #edf6f4;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
      "
    >
      <div style="width: 100%" class="pl-5">
        <h1 style="font-size: 1.5rem; font-weight: 700" class="mb-3">
          Kategori Utama
        </h1>
      </div>
      <v-layout>
        <v-row>
          <CardIconComponent
            title="Tanah"
            image="tanah.svg"
            navigation="/aset/tanah"
          />
          <CardIconComponent
            title="Mesin dan Peralatan"
            image="mesin_peralatan.svg"
            navigation="/aset/mesin-peralatan"
          />
          <CardIconComponent
            title="Gedung dan Bangunan"
            image="gangguan_aset.svg"
            navigation="/aset/gedung-bangunan"
          />
          <CardIconComponent
            title="Jalan, Irigasi dan Jaringan"
            image="jalan_irigasi_perbaikan.svg"
            navigation="/aset/jalan-irigasi-jaringan"
          />
          <CardIconComponent
            title="Aset Tak Berwujud"
            image="aset_tak_berwujud.svg"
            navigation="/aset/aset-tak-berwujud"
          />
        </v-row>
      </v-layout>
    </div>
    <div class="mt-8">
      <h1 style="font-size: 1.5rem; font-weight: 700" class="mb-8">
        Laporan Aset Lainnya
      </h1>
      <div>
        <v-container class="mb-6" fluid>
          <v-row style="border: 1px #abd1c6 solid; border-radius: 10px">
            <v-col
              class="py-8 align-center justify-center"
              style="
                display: flex;
                border-right: 1px #abd1c6 solid;
                cursor: pointer;
              "
              @click="navigateToPage('/aset/laporan-aset')"
              >List Laporan Aset</v-col
            >
            <v-col
              class="py-8 align-center justify-center"
              style="display: flex; cursor: pointer"
              @click="navigateToPage('/aset/pemeliharaan-aset')"
              >List Pemeliharaan Aset</v-col
            >
          </v-row>
        </v-container>
        <v-container
          style="
            border: 1px #abd1c6 solid;
            border-radius: 10px;
            padding-right: 0;
            padding-left: 0;
          "
          fluid
        >
          <v-layout style="border-bottom: 1px #abd1c6 solid; width: inherit">
            <v-row>
              <v-col
                class="py-8 align-center justify-center"
                style="
                  display: flex;
                  border-right: 1px #abd1c6 solid;
                  cursor: pointer;
                "
                @click="navigateToPage('/aset/aset-dihibahkan')"
                >List Aset Dihibahkan</v-col
              >
              <v-col
                class="py-8 align-center justify-center"
                style="
                  display: flex;
                  border-right: 1px #abd1c6 solid;
                  cursor: pointer;
                "
                @click="navigateToPage('/aset/aset-dimusnahkan')"
                >List Aset Dimusnahkan</v-col
              >
              <v-col
                class="py-8 align-center justify-center"
                style="display: flex; cursor: pointer"
                @click="navigateToPage('/aset/aset-dilelang')"
                >List Aset Dilelang</v-col
              >
            </v-row>
          </v-layout>
          <v-layout>
            <v-row>
              <v-col
                class="py-8 align-center justify-center"
                style="
                  display: flex;
                  border-right: 1px #abd1c6 solid;
                  cursor: pointer;
                "
                @click="navigateToPage('/aset/aset-diputihkan')"
                >List Aset Diputihkan</v-col
              >
              <v-col
                class="py-8 align-center justify-center"
                style="
                  display: flex;
                  border-right: 1px #abd1c6 solid;
                  cursor: pointer;
                "
                @click="navigateToPage('/aset/aset-kerjasama')"
                >List Kerjasama Operasi</v-col
              >
              <v-col
                class="py-8 align-center justify-center"
                style="display: flex; cursor: pointer"
                @click="navigateToPage('/aset/aset-ditingkatkan')"
                >List Aset Ditingkatkan</v-col
              >
            </v-row>
          </v-layout>
        </v-container>
        <p
          @click="navigateToPage('/aset/aset-dihapus')"
          class="text-center my-8"
          style="
            font-weight: 300;
            font-size: 14px;
            color: #001e1d;
            cursor: pointer;
          "
        >
          <u>List Aset Dihapus</u>
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import * as constant from "@/constants/constant";
import Cookies from "js-cookie";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async updateChartData(chartType, chartOptions, chartData, apiEndpoint) {
      const response = await this.$axios.post(apiEndpoint, {
        type: this.type,
        date_start: this.date_start,
        date_end: this.date_end,
      });

      chartOptions.xaxis.categories = response.data.categories;
      console.log(response.data);
      chartData[0].data = response.data["x-data"];

      if (this.$refs[chartType]) {
        this.$refs[chartType].updateSeries(chartData);
        this.$refs[chartType].updateOptions(chartOptions);
      }
    },
    async init() {
      if (Cookies.get("loginResponse") == undefined)
        window.location.replace("/login");
      this.account = JSON.parse(Cookies.get("loginResponse"));
      await this.updateChartData(
        "assetFluctuationChart",
        this.chartOptionsAssetFluctuation,
        this.assetFluctuation,
        "/chart-asset-fluctuation"
      );

      await this.updateChartData(
        "damagedAssetChart",
        this.chartOptionsDamagedAsset,
        this.damagedAssets,
        "/chart-asset-damaged"
      );
    },
    async recallData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date_start = dateFrom;
        this.date_end = dateTo;
      }

      await this.updateChartData(
        "assetFluctuationChart",
        this.chartOptionsAssetFluctuation,
        this.assetFluctuation,
        "/chart-asset-fluctuation"
      );

      await this.updateChartData(
        "damagedAssetChart",
        this.chartOptionsDamagedAsset,
        this.damagedAssets,
        "/chart-asset-damaged"
      );
    },
    navigateToPage(url) {
      window.location.href = url;
    },
    onChange() {
      const inputElement = this.$refs.fileInput;
      if (inputElement.files.length > 0) {
        this.selectedFile = inputElement.files[0];
      }
    },

    async uploadFile() {
      const formData = new FormData();
      formData.append("excel_file", this.selectedFile);

      const response = await this.$axios.post("/upload-assets", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.selectedFile = null;
      this.message = response.data.status;
      this.snackbar = true;
      this.dialog = false;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;

      if (files.length > 0) {
        const file = files[0];
        if (file.name.endsWith(".xlsx")) {
          this.selectedFile = file;
          this.isDragging = false;
        }
      }
    },
    clearSelectedFile() {
      this.selectedFile = null;
      const inputElement = this.$refs.fileInput;
      inputElement.value = "";
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      account: null,
      scopeConstant: constant.SCOPES,
      snackbar: false,
      message: "",
      selectedFile: null,
      assetFluctuation: [{ name: "Asset Fluctuation", data: [] }],
      damagedAssets: [{ name: "Damaged Assets", data: [] }],
      date_start: initialDate.date_start,
      date_end: initialDate.date_end,
      type: "month",
      crumbs: [
        {
          text: "Layanan Aset",
          disabled: true,
          href: "/aset",
        },
      ],
      isDragging: false,
      files: [],
      dialog: false,
      chartOptionsAssetFluctuation: {
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
          },
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptionsDamagedAsset: {
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
          },
        },
        xaxis: {
          categories: [],
        },
        colors: ["#FF1654"],
      },
    };
  },
};
</script>

<style>
.dropzone-container {
  padding: 4rem;
  border: 2px #e8e4e6 dotted;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.v-carousel__controls {
  background: none;
}
.mdi-circle::before {
  color: #d9d9d9;
}

.v-btn--active .mdi-circle::before {
  color: #004643;
}
</style>
