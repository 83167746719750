<template>
  <a
    :class="[
      'my-5',
      'px-5',
      'py-7',
      'mr-4',
      'rounded-xl',
      'align-center',
      'justify-center',
      'text-decoration-none',
      { 'disabled-link': isDisabled },
    ]"
    style="
      background: #ffffff;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
      height: auto;
      width: 18%;
    "
    :href="isDisabled ? null : navigation"
  >
    <div
      style="
        display: flex;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 2.5px #e16162 solid;
      "
      class="align-center justify-center"
    >
      <img
        :src="require('../assets/icons/' + image)"
        style="height: 40px; width: 40px"
      />
    </div>
    <h4 class="pt-4" style="text-align: center">{{ title }}</h4>
  </a>
</template>

<script>
export default {
  name: "CardIconComponent",
  props: ["title", "image", "card-size", "navigation", "disabled"],
  computed: {
    isDisabled() {
      return this.disabled || false;
    },
  },
};
</script>

<style scoped>
.disabled-link {
  pointer-events: none;
  opacity: 0.6;
}
</style>
