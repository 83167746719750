<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div class="mb-7">
      <title-component :title="title" />
      <breadcrumbs-component :crumbs="crumbs" />
    </div>
    <v-row>
      <v-col cols="6"
        ><category-detail-card
          :asset-category="dataResponse.asset_category"
          :asset-sub-category="dataResponse.asset_sub_category"
          :asset-advance-category="dataResponse.asset_advance_category"
          :location="dataResponse.location"
      /></v-col>
      <v-col cols="6"
        ><card-photo-group-component :images="dataResponse.images"
      /></v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="4"
        ><description-laporan-component
          :desc-final="dataResponse.desc_final"
          :desc-follow-up="dataResponse.desc_follow_up || '-'"
          :desc_initial="dataResponse.desc_initial"
      /></v-col>
      <v-col cols="4"
        ><card-pelapor-component
          :final-reporter="dataResponse.final_reporter"
          :follow-up-reporter="dataResponse.follow_up_reporter || '-'"
          :reporter="dataResponse.initial_reporter"
      /></v-col>
      <v-col cols="4"
        ><countdown-laporan-component
          :status="dataResponse.status"
          :initial-report-time="
            getFormattedDateTime(dataResponse.initial_report_time) || '-'
          "
          :final-report-time="
            getFormattedDateTime(dataResponse.final_report_time) || '-'
          "
          :follow-up-report-time="
            getFormattedDateTime(dataResponse.follow_up_report_time) || '-'
          "
          :completedTime="
            calculateTimeDifference(
              dataResponse.initial_report_time,
              dataResponse.final_report_time
            )
          "
      /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import CategoryDetailCard from "@/components/CategoryDetailCard";
import CardPelaporComponent from "@/components/CardPelaporComponent";
import CountdownLaporanComponent from "@/components/CountdownLaporanComponent";
import DescriptionLaporanComponent from "@/components/DescriptionLaporanComponent";
import CardPhotoGroupComponent from "@/components/CardPhotoGroupComponent";

export default {
  beforeMount() {
    this.setDataReport();
  },
  methods: {
    setDataReport() {
      this.$axios.get(`/final-report/${this.$route.params.id}`).then(
        (response) => {
          console.log(response.data);
          this.images = response.data.data.images;
          this.dataResponse = response.data.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getFormattedDateTime(datetime) {
      const createdAt = new Date(datetime);
      const hours = String(createdAt.getHours()).padStart(2, "0");
      const minutes = String(createdAt.getMinutes()).padStart(2, "0");
      const date = String(createdAt.getDate()).padStart(2, "0");
      const month = String(createdAt.getMonth()).padStart(2, "0");
      return `${hours}:${minutes} - ${date}/${month}/${createdAt.getFullYear()}`;
    },
    calculateTimeDifference(initialCreatedAt, createdAt) {
      const initialDate = new Date(initialCreatedAt);
      const endDate = new Date(createdAt);

      let timeDifferenceInMillis = endDate - initialDate;

      const isNegative = timeDifferenceInMillis < 0;
      if (isNegative) {
        timeDifferenceInMillis *= -1;
      }

      const hours = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifferenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifferenceInMillis % (1000 * 60)) / 1000);

      const formattedTime = `${
        (isNegative ? "-" : "") + String(hours).padStart(2, "0")
      }:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;

      return formattedTime;
    },
  },
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    CategoryDetailCard,
    DescriptionLaporanComponent,
    CountdownLaporanComponent,
    CardPelaporComponent,
    CardPhotoGroupComponent,
  },
  computed: {
    crumbs() {
      return [
        {
          text: "Layanan Pemeliharaan",
          disabled: false,
          href: "/layanan-pemeliharaan",
        },
        {
          text: `Laporan Final (${this.$route.params.id})`,
          disabled: true,
          href: `/layanan-pemeliharaan/final/${this.$route.params.id}`,
        },
      ];
    },
  },
  data: () => ({
    idData: "",
    dataResponse: null,
    images: [
      "https://www.pockettactics.com/wp-content/sites/pockettactics/2023/04/Genshin-Impact-Ganyu-1.jpg",
      "https://www.pockettactics.com/wp-content/sites/pockettactics/2023/05/honkai-star-rail-bronya-1.jpeg",
    ],
    title: "Layanan Pemeliharaan",
  }),
};
</script>

<style></style>
