var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{class:[
    'my-5',
    'px-5',
    'py-7',
    'mr-4',
    'rounded-xl',
    'align-center',
    'justify-center',
    'text-decoration-none',
    { 'disabled-link': _vm.isDisabled },
  ],staticStyle:{"background":"#ffffff","display":"flex","flex-direction":"column","box-shadow":"0px 2px 10px rgba(232, 228, 230, 0.7)","height":"auto","width":"18%"},attrs:{"href":_vm.isDisabled ? null : _vm.navigation}},[_c('div',{staticClass:"align-center justify-center",staticStyle:{"display":"flex","width":"100px","height":"100px","border-radius":"50%","border":"2.5px #e16162 solid"}},[_c('img',{staticStyle:{"height":"40px","width":"40px"},attrs:{"src":require('../assets/icons/' + _vm.image)}})]),_c('h4',{staticClass:"pt-4",staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.title))])])
}
var staticRenderFns = []

export { render, staticRenderFns }