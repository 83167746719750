<template>
  <v-row style="padding: 0 !important; margin: 0 !important">
    <v-col cols="6" style="padding: 0 !important">
      <v-container :class="$style.mainBgColor" style="height: 100vh">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3 mt-16"
          contain
          height="250"
        />

        <v-container style="margin: 140px auto; width: 80%">
          <div :class="$style['yellow-rect']"></div>
          <p :class="$style['paragraph-title']">
            An innovative and sophisticated system specifically designed to
            facilitate efficient and integrated management, providing direct and
            seamless access to key stakeholders.
          </p>
        </v-container>
      </v-container>
    </v-col>
    <v-col :class="$style['background-container']" cols="6">
      <v-container style="margin: 20vh auto" class="px-16">
        <h1 :class="$style['big-title']">Selamat Datang di IOMS 👋</h1>
        <h6 :class="$style['sub-title']">
          Silakan masuk dengan akun yang telah terdaftar
        </h6>
        <v-text-field
          v-model="nik"
          label="Username"
          counter
          style="margin-top: 25px"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          counter
          @click:append="show1 = !show1"
          style="margin-top: 25px"
        ></v-text-field>
        <v-btn
          class="white--text text-capitalize"
          color="#004643"
          width="100%"
          height="34"
          style="margin-top: 25px"
          @click="login"
        >
          Masuk
        </v-btn>
      </v-container>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import * as constant from "../constants/constant.js";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: constant.APP_TITLE,
  data: () => ({
    nik: "",
    password: "",
    show1: false,
    snackbar: false,
    timeout: 3000,
    message: "",
  }),
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      if (Cookies.get("loginResponse") !== undefined)
        window.location.replace("/");
    },
    async login() {
      if (this.nik == "") {
        this.snackbar = true;
        this.message = "Kolom NIK harus diisi";
        return;
      }

      if (this.password == "") {
        this.snackbar = true;
        this.message = "Kolom password harus diisi";
        return;
      }

      try {
        let jsonData = {
          nik: this.nik,
          password: this.password,
        };
        const response = await axios.post(
          constant.BASE_URL + "/auth-web",
          jsonData
        );
        console.log(response);
        if (response.data.status == "Ok") {
          Cookies.set("loginResponse", JSON.stringify(response.data), 5);
          axios.defaults.headers.common["Authorization"] =
            response.data.session;
          window.location.replace("/");
        } else {
          this.snackbar = true;
          this.message = response.data.status;
        }
      } catch (error) {
        // Handle errors (e.g., display an error message to the user)
        console.error("Login failed:", error);
        // TODO: add snackbar to show error
        this.snackbar = true;
        this.message = error.message;
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "../styles/main.module.scss";
</style>
